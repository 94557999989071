import pageheader from "../../common/pageheader";
import InfoDialog from "@/components/common/infodialog";
export default {
  name: "reports",
  components: { pageheader, InfoDialog },
  props: [],
  data() {
    return {
      reportInfo: {},
      input: {
        icon: "mdi-file-document",
        name: "Support Tools",
        description:
          "<div>Here you can download and schedule the reports for your assets.<br> Based on your subscription plan different reports will be available.</div>"
      },
      builds: [
        {
          isCard: true,
          name: "Service List Upload",
          description:
            "<p>Please click the below link to access Service List. </p><p><b>Note: </b> Please download the below template for reference. Column names should exactly match as given in template to process.</p>",
          icon: "mdi-calendar-clock",
          id: "service_list_upload"
        }
      ]
    };
  },
  computed: {},
  mounted() {},
  methods: {
    openModal(build) {
      this.reportInfo = build;
      this.$refs.modal.showModal();
    },
    navigate(build) {
      this.$router.push({
        name: "Support Tools List",
        params: { id: build.id }
      });
    }
  }
};
